// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainbody-container {
  width: 100%;
}

.mainbody-container-hidden {
    visibility: hidden;
}

.mainbody-container-shift {
  background-color: #1b262c;
  margin-left: 150px;
}

@media screen and (max-width: 1300px) {
  .mainbody-container-shift {
    margin-left: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Mainbody/Mainbody.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".mainbody-container {\n  width: 100%;\n}\n\n.mainbody-container-hidden {\n    visibility: hidden;\n}\n\n.mainbody-container-shift {\n  background-color: #1b262c;\n  margin-left: 150px;\n}\n\n@media screen and (max-width: 1300px) {\n  .mainbody-container-shift {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
